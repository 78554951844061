import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {File} from '@ionic-native/file/ngx';
import {NgxSimpleCalendarModule} from 'ngx-simple-calendar';


import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import {ReactiveFormsModule} from "@angular/forms";

FullCalendarModule.registerPlugins([
    interactionPlugin,
    dayGridPlugin
]);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({}),
        AppRoutingModule,
        HttpClientModule,
        NgxSimpleCalendarModule,
        FullCalendarModule,
        ReactiveFormsModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
        ,
        DocumentViewer,
        FileTransfer,
        FileOpener,
        File,
        InAppBrowser,
        AndroidPermissions
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
