import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '../variable-globale';
import {Plugins} from '@capacitor/core';

const {Storage} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) {

    }

    signIn(user_name: string, password: string) {
        const data = {
            pseudo: user_name,
            password: password
        };
        return new Promise(
            (resolve, reject) => {
                this.http.post(BASEURL + 'login', data).subscribe(
                    async (data: any) => {
                        await Storage.set({key: 'session', value: JSON.stringify(data)});
                        await Storage.set({key: 'isAcces', value: String(true)});
                        resolve(true);
                    }, (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
            }
        );
    }

    async signOut() {
        await Storage.set({key: 'session', value: '{}'});
        await Storage.set({key: 'isAcces', value: String(false)});
    }

    isAcces() {
        return new Promise((resolve, reject) => {
            Storage.get({key: 'isAcces'}).then(
                (v) => {
                    if (v.value === 'true') {
                        resolve(true);
                    } else if (v.value === 'false') {
                        reject(false);
                    } else {
                        reject(false);
                    }
                }
            );
        });
    }

    session() {
        return new Promise((resolve, reject) => {
            Storage.get({key: 'session'}).then(
                (v) => {
                    if (v.value !== null) {
                        resolve(JSON.parse(v.value));
                    } else {
                        reject(false);
                    }
                }
            );
        });
    }
}
